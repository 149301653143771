import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Company from '@/models/Company';

export default class ImportRepository extends EntityBaseRepository {
    public importCSVFile(company: Company, importType: string, detectedDelimiter: string, csvFile: any): Promise<any> {
        const bodyFormData = new FormData();
        bodyFormData.append('csvFile', csvFile);
        return this.axiosClient.post(`/import/${company.id}/importAny`, bodyFormData,
            {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {
                    delimiter: detectedDelimiter,
                    singleType: importType,
                },
            });
    }

    public getExampleFile(importType: string): Promise<any> {
        return this.axiosClient.get(`${this.axiosClient.defaults.baseURL}import/loadExampleFile/${importType}`,
            {responseType: 'blob'});
    }
}
