import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import ApiError from '@/api/misc/ApiError';
import { Vue } from 'vue-property-decorator';

/**
 * Base repository for auth repositories
 */
export default class AuthBaseRepository {

    protected axiosClient!: AxiosInstance;
    private baseURL: string = process.env.VUE_APP_BASE_URL!;
    private authURL: string = process.env.VUE_APP_AUTH_URL!;
    private version: string = process.env.VUE_APP_VERSION!;

    constructor() {
        this.createHttpClient();
        this.setResponseInterceptors();
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: `${this.baseURL}${this.authURL}`,
            headers: {
                'Content-Type': 'application/json',
                'client-version': this.version,
                'client-type': 'web',
            },
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        // normalize response
        this.axiosClient.interceptors.response.use(
            (response) => response.data,
            (error) => {
                if (error.response) {
                    console.log(error, error.response);
                    if (error.response.status >= 500) {
                        switch (error.response.status) {
                            // handle api incompatibility
                            case 905:
                                Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.INCOMPATIBILITY_ERROR');
                                break;
                            case 906:
                                Vue.notifyErrorSimplified('LOGIN.NOTIFICATIONS.906');
                                break;
                            default:
                                Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
                        }
                        return new Promise(() => {
                            // cancels process of the interceptor chain
                        });
                    } else {
                        throw new ApiError(error.response.status, error.message, error.response.data.data);
                    }
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
                    return new Promise(() => {
                        // cancels process of the interceptor chain
                    });
                }
            });
    }
}
