import { ActionTree, Module } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import AuthRepository from '@/api/repositories/AuthRepository';
import User from '@/models/User';
import {AuthResponse} from '@/interfaces/Responses';

const authRepository: AuthRepository = RepositoryFactory.get('auth');

const store = {
    token: undefined,
    user: undefined,
    refreshToken: undefined,
    isRefreshingToken: false,
    failedQueue: [],
};

const actions: ActionTree<any, any> = {
    loginAction: async ({commit}, payload: { username: string, password: string }): Promise<User> => {
        const loginDataRaw = await authRepository.login(payload);
        loginDataRaw.user = User.parseFromObject(loginDataRaw.user);

        commit('saveLogin', loginDataRaw);
        return loginDataRaw.user;
    },
    validateHashAction: async ({commit}, hash: string): Promise<void> => {
        return authRepository.validateHash(hash);
    },
    setPasswordAction: async ({commit}, payload: { hash: string, password: string }):
      Promise<AuthResponse> => {
        return await authRepository.setPassword(payload);
    },
    resetPasswordAction: async ({commit}, login: string): Promise<void> => {
        await authRepository.resetPassword(login);
    },
    refreshToken: async ({commit}, token: string): Promise<AuthResponse> => {
        const refreshRaw = await authRepository.refreshToken(token);
        commit('saveLogin', refreshRaw);
        return refreshRaw;
    },
};

const mutations = {
    saveLogin: (state: any, payload: AuthResponse) => {
        state.user = payload.user;
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
    },
    clearLogin: (state: any) => {
        state.user = undefined;
        state.token = undefined;
        state.refreshToken = undefined;
    },
    saveUser: (state: any, user: any) => {
        state.user = User.parseFromObject(user);
    },
    clearFailedQueue: (state: any) => {
        state.failedQueue = [];
    },
    pushFailedRequest: (state: any, payload: { resolve: any, reject: any }) => {
      state.failedQueue.push(payload);
    },
    setIsRefreshing: (state: any, isRefreshing: boolean) => {
        state.isRefreshingToken = isRefreshing;
    },
};

const getters = {
    token: (state: any) => state.token,
    user: (state: any) => state.user,
    refreshToken: (state: any) => state.refreshToken,
    isRefreshingToken: (state: any) => state.isRefreshingToken,
    failedQueue: (state: any) => state.failedQueue,
};

const authStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default authStore;
