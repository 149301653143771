import {AxiosResponse} from 'axios';
import axios from 'axios';
import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';

export default class MapRepository extends EntityBaseRepository {

    public async getAddress(street: string): Promise<AxiosResponse> {
        const url =  process.env.VUE_APP_GEO_URL!;
        return axios.get(url, {
            params: {
                format: process.env.VUE_APP_FORMAT!,
                street,
                country: 'Germany',
                addressdetails: 1,
            },
        });
    }

    public async getAddressByCoordinates(lat: number, lon: number): Promise<any> {
        const url =  process.env.VUE_APP_GEO_URL_REVERSE!;
        return axios.get(url, {
            params: {
                format: process.env.VUE_APP_FORMAT!,
                lat,
                lon,
            },
        });
    }
}
