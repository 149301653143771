import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import CleanTime from '@/models/CleanTime';
import CleanTimeRepository from '@/api/repositories/CleanTimeRepository';
import Area from '@/models/Area';
import Task from '@/models/Task';

const cleanTimeRepository: CleanTimeRepository = RepositoryFactory.get('cleanTime');

enum cleanTimeState {
  CLEAN_TIMES = 'cleanTimes',
  COLORS = 'colors',
}

const store = {
  [cleanTimeState.CLEAN_TIMES]: [],
  [cleanTimeState.COLORS]: {},
};

export enum cleanTimeStoreActions {
  CANCEL_REQUESTS_ACTION = 'cancelRequestsAction',
  UPDATE_CLEAN_TIME_ACTION = 'updateCleanTimeAction',
  GET_CLEAN_TIME_ACTION = 'getCleanTimeAction',
  CREATE_CLEAN_TIME_ACTION = 'createCleanTimeAction',
  DELETE_CLEAN_TIME_ACTION = 'deleteCleanTimeAction',
  LOAD_CLEAN_TIMES_ACTION = 'loadCleanTimesAction',
}

const actions: ActionTree<any, any> = {
  [cleanTimeStoreActions.CANCEL_REQUESTS_ACTION]: async () => {
    cleanTimeRepository.cancelRequests();
  },
  [cleanTimeStoreActions.UPDATE_CLEAN_TIME_ACTION]: async ({commit}, payload: {
    cleanTime: CleanTime,
    splitAtDate: string,
  }): Promise<CleanTime> => {
    // remove the occurrence now here. Maybe its useful in the future somewhere else, so do not delete it now
    for (const time of payload.cleanTime.times) {
      if (!time.occurrences) {
        delete time.occurrences;
      }
    }
    const cleanTimeRaw = await cleanTimeRepository.updateCleanTime(payload);
    commit(cleanTimeStoreMutations.UPDATE_CLEAN_TIMES, payload.cleanTime);
    return CleanTime.parseFromObject(cleanTimeRaw);
  },
  [cleanTimeStoreActions.GET_CLEAN_TIME_ACTION]: async ({commit}, cleanTimeId: string): Promise<CleanTime> => {
    const cleanTimeRaw = await cleanTimeRepository.getCleanTime(cleanTimeId);
    // TODO put this sorting below into a better place
    // sorts in ascending order i.e 0,1,2,3...
    if (cleanTimeRaw.areas[0].order !== -1) {
      cleanTimeRaw.areas.sort((a: Area, b: Area) => a.order - b.order);
      cleanTimeRaw.areas.forEach((area: Area) => area.tasks.sort((a: Task, b: Task) => a.order - b.order));
    }

    return CleanTime.parseFromObject(cleanTimeRaw);
  },
  [cleanTimeStoreActions.LOAD_CLEAN_TIMES_ACTION]: async ({commit}, locationId: string): Promise<CleanTime[]> => {
    const cleanTimesRaw = await cleanTimeRepository.loadCleanTimes(locationId);
    commit(cleanTimeStoreMutations.STORE_CLEAN_TIMES, cleanTimesRaw.records);
    return CleanTime.parseFromArray(cleanTimesRaw.records) as CleanTime[];
  },
  [cleanTimeStoreActions.CREATE_CLEAN_TIME_ACTION]: async ({commit}, cleanTime: CleanTime): Promise<any> => {
    // remove the occurrence now here. Maybe its useful in the future somewhere else, so do not delete it now
    for (const time of cleanTime.times) {
      if (!time.occurrences) {
        delete time.occurrences;
      }
    }
    const cleanTimeRaw = await cleanTimeRepository.createCleanTime(cleanTime);
    const createdCleanTime = CleanTime.parseFromObject(cleanTimeRaw);
    commit(cleanTimeStoreMutations.STORE_CLEAN_TIME, createdCleanTime);
    return createdCleanTime;
  },
  [cleanTimeStoreActions.DELETE_CLEAN_TIME_ACTION]: async ({commit}, cleanTimeId: string): Promise<void> => {
    await cleanTimeRepository.deleteCleanTime(cleanTimeId);
    commit(cleanTimeStoreMutations.REMOVE_CLEAN_TIME, cleanTimeId);
  },
};

export enum cleanTimeStoreMutations {
  STORE_CLEAN_TIMES = 'storeCleanTimes',
  STORE_CLEAN_TIME = 'storeCleanTime',
  UPDATE_CLEAN_TIMES = 'updateCleanTimes',
  REMOVE_CLEAN_TIME = 'removeCleanTime',
}

const mutations = {
  [cleanTimeStoreMutations.STORE_CLEAN_TIMES]: (state: any, cleanTimes: CleanTime[]) => state[cleanTimeState.CLEAN_TIMES] = cleanTimes,
  [cleanTimeStoreMutations.STORE_CLEAN_TIME]: (state: any, cleanTime: CleanTime) => state[cleanTimeState.CLEAN_TIMES].push(cleanTime),
  [cleanTimeStoreMutations.UPDATE_CLEAN_TIMES]: (state: any, cleanTime: CleanTime) => {
    const index = state[cleanTimeState.CLEAN_TIMES].findIndex((item: CleanTime) => item.id === cleanTime.id);
    if (index >= 0) {
      state[cleanTimeState.CLEAN_TIMES].splice(index, 1, cleanTime);
    } else {
      state[cleanTimeState.CLEAN_TIMES].push(cleanTime);
    }
  },
  [cleanTimeStoreMutations.REMOVE_CLEAN_TIME]: (state: any, cleanTimeId: string) => {
    const index = state[cleanTimeState.CLEAN_TIMES].findIndex((item: CleanTime) => item.id === cleanTimeId);
    state[cleanTimeState.CLEAN_TIMES].splice(index, 1);
  },
};

export enum cleanTimeStoreGetter {
  CLEAN_TIMES = 'cleanTimes',
}

const getters = {
  [cleanTimeStoreGetter.CLEAN_TIMES]: (state: any) => state[cleanTimeState.CLEAN_TIMES],
};

const cleanTimeStore: Module<any, any> = {
  state: store,
  actions,
  mutations,
  getters,
};

export default cleanTimeStore;
