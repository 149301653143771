import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import FileRepository from '@/api/repositories/FileRepository';
import DbFile, {FileEntities, Label} from '@/models/DbFile';

const fileRepository: FileRepository = RepositoryFactory.get('file');

enum fileStoreState {
  FILES = 'files',
  LABELS = 'labels',
}

const store = {
  /**
   * The cached files and labels
   */
  [fileStoreState.FILES]: [],
  [fileStoreState.LABELS]: [],
};

export enum fileStoreActions {
  CREATE_FILE_ACTION = 'createFileAction',
  LOAD_FILES_ACTION = 'loadFilesAction',
  DOWNLOAD_FILES_ACTION = 'downloadFilesAction',
  LOAD_FILE_ACTION = 'loadFileAction',
  DELETE_FILE_ACTION = 'deleteFileAction',
  CREATE_LABEL_ACTION = 'createLabelAction',
  LOAD_LABELS_ACTION = 'loadLabelsAction',
  DELETE_LABEL_ACTION = 'deleteLabelAction',
}

const actions: ActionTree<any, any> = {
  [fileStoreActions.CREATE_FILE_ACTION]: async ({commit}, body: FormData) => {
    const fileResponse = await fileRepository.createFile(body);
    commit(fileStoreMutations.Add_FILE, fileResponse);
    return fileResponse;
  },
  [fileStoreActions.LOAD_FILES_ACTION]: async ({commit}, payload: { entity: FileEntities, value: string }) => {
    const fileResponse = await fileRepository.getAllFiles(payload.entity, payload.value);
    const files = DbFile.parseFromArray(fileResponse.records);
    commit(fileStoreMutations.SAVE_FILES, files);
    return files;
  },
  [fileStoreActions.DOWNLOAD_FILES_ACTION]: async ({commit}, fileId: string) => {
    return fileRepository.downloadFile(fileId);
  },
  [fileStoreActions.LOAD_FILE_ACTION]: async ({commit}, payload: { id: string, populate: string[] }) => {
    return fileRepository.getFile(payload.id, payload.populate);
  },
  [fileStoreActions.DELETE_FILE_ACTION]: async ({commit}, id: string) => {
    await fileRepository.deleteFile(id);
    commit(fileStoreMutations.REMOVE_FILE, id);
  },
  [fileStoreActions.CREATE_LABEL_ACTION]: async ({commit}, body: Label) => {
    const labelResponse = await fileRepository.createLabel(body);
    commit(fileStoreMutations.Add_LABEL, labelResponse);
    return labelResponse;
  },
  [fileStoreActions.LOAD_LABELS_ACTION]: async ({commit}, customerId: string) => {
    const labels = await fileRepository.getLabels(customerId);
    commit(fileStoreMutations.SAVE_LABELS, labels.records);
    return labels.records;
  },
  [fileStoreActions.DELETE_LABEL_ACTION]: async ({commit}, id: string) => {
    await fileRepository.deleteLabel(id);
    commit(fileStoreMutations.REMOVE_LABEL, id);
  },
};

export enum fileStoreMutations {
  SAVE_FILES = 'saveFiles',
  Add_FILE = 'addFile',
  REMOVE_FILE = 'removeFile',
  SAVE_LABELS = 'saveLabels',
  Add_LABEL = 'addLabel',
  REMOVE_LABEL = 'removeLabel',
}

const mutations = {
  [fileStoreMutations.SAVE_FILES]: (state: any, files: DbFile[]) => state[fileStoreState.FILES] = files,
  [fileStoreMutations.Add_FILE]: (state: any, file: DbFile) => state[fileStoreState.FILES].push(file),
  [fileStoreMutations.REMOVE_FILE]: (state: any, fileId: string) =>
    state[fileStoreState.FILES] = state[fileStoreState.FILES].filter((file: DbFile) => file.id !== fileId),
  [fileStoreMutations.SAVE_LABELS]: (state: any, labels: Label[]) => state[fileStoreState.LABELS] = labels,
  [fileStoreMutations.Add_LABEL]: (state: any, label: Label) => state[fileStoreState.LABELS].push(label),
  [fileStoreMutations.REMOVE_LABEL]: (state: any, labelId: string) =>
    state[fileStoreState.LABELS] = state[fileStoreState.LABELS].filter((file: DbFile) => file.id !== labelId),
};

export enum fileStoreGetter {
  FILES = 'files',
  LABELS = 'labels',
}

const getters = {
  [fileStoreGetter.FILES]: (state: any) => state[fileStoreState.FILES],
  [fileStoreGetter.LABELS]: (state: any) => state[fileStoreState.LABELS],
};

const fileStore: Module<any, any> = {
  state: store,
  actions,
  mutations,
  getters,
};

export default fileStore;
