import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import CleanTime from '@/models/CleanTime';
import {CancelToken, CancelTokenSource} from 'axios';
import {FindAllResponse} from '@/interfaces/Responses';

export default class CleanTimeRepository extends EntityBaseRepository {

  private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
  private cancelTokenSource!: CancelTokenSource;

  constructor() {
    super();
    this.cancelTokenSource = this.cancelTokenFactory.source();
    this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
  }

  public getCleanTime(cleanTimeId: string): Promise<CleanTime> {
    return this.axiosClient.get(`cleanTime/${cleanTimeId}`, this.cancelTokenSourceOption);
  }

  public loadCleanTimes(locationId: string): Promise<FindAllResponse<CleanTime>> {
    return this.axiosClient.get(`cleanTime?locationId=${locationId}&populate=areas,plannedUsers`);
  }

  public createCleanTime(cleanTime: CleanTime): Promise<CleanTime> {
    return this.axiosClient.post(`/cleanTime`, cleanTime, this.cancelTokenSourceOption);
  }

  public deleteCleanTime(cleanTimeId: string): Promise<void> {
    return this.axiosClient.delete(`cleanTime/${cleanTimeId}`, this.cancelTokenSourceOption);
  }

  public updateCleanTime(payload: { cleanTime: CleanTime, splitAtDate: string }): Promise<CleanTime> {
    return this.axiosClient.patch(`cleanTime/${payload.cleanTime.id}?splitAtDate=${payload.splitAtDate}`,
      payload.cleanTime, this.cancelTokenSourceOption);
  }

  public cancelRequests() {
    this.cancelTokenSource.cancel('cancelled by ui');
    this.cancelTokenSource = this.cancelTokenFactory.source();
    this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
  }
}
