import Address from '@/models/Address';
import ContactPerson from '@/models/ContactPerson';
import CleanTime from '@/models/CleanTime';
import Area from '@/models/Area';
import Task from '@/models/Task';
import User from '@/models/User';
import Customer from '@/models/Customer';
import Machine from './Machine';
import {VersionControl} from '@/misc/VersionControl';

export default class Location extends VersionControl {

  public static parseFromObject(object: Partial<Location>): Location {
    const location = new Location();

    Object.assign(location, object);

    if (object.addressId === '') {
      object.addressId = null;
      // if the id is an empty string, its invalid and needs to be set to a value or null
    } else
    if (object.address) {
      location.address = Address.parseFromObject(object.address!);
    }
    // Some objects don't have an address
    if (object.contactPersonId === '') {
      location.contactPersonId = null;
    } else
    if (object.contactPerson) {
      location.contactPerson = ContactPerson.parseFromObject(object.contactPerson!);
    }
    if (object.customer) {
      location.customer = Customer.parseFromObject(object.customer!);
    }
    object.cleanTimes = CleanTime.parseFromArray(object.cleanTimes!) as CleanTime[];
    object.managers = User.parseFromArray(object.managers!) as User[];
    object.machines = Machine.parseFromArray(object.machines!) as Machine[];

    return location;
  }

  public id?: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public fax?: string;
  public description?: string;
  public size!: string;
  public radius?: number;
  public customerSignNeeded?: boolean;
  public employeeSignNeeded?: boolean;
  public active!: boolean;

  public floor!: string;
  public machines: Machine[]; // default value, if they don't have machines

  public customer?: Customer;
  public customerId!: string;

  /**
   * contactPerson can only be null, when it should be derived from customer (request data)
   */
  public contactPerson?: ContactPerson | null;
  public contactPersonId?: string | null;

  public managers: User[] = [];

  /**
   * Address can only be null, when it should be derived from customer
   */
  public address?: Address | null;
  public addressId!: string | null;

  public createdAt!: Date;
  public updatedAt!: Date;
  public deleted!: boolean;
  public vcOriginId?: string | null;
  public companyId!: string;

  // TODO: Delete these
  public cleanTimes: CleanTime[] = [];

  // TODO: Delete these
  public areas: Area[] = [];
  public areasCount!: number;
  public areasCountActive!: number;
  public tasks: Task[] = [];


  constructor() {
    super();
    this.address = new Address();
    this.contactPerson = new ContactPerson();
    this.machines = [new Machine(), new Machine()];
  }

  /**
   * Return a swallow object copy
   */
  public copy(): Location {
    return Location.parseFromObject(this.parseToObject());
  }

  // RN-388 just a swallow copy ... maybe this is not enough...
  public parseToObject(): Partial<Location> {
    const tmp: any = {...this};
    tmp.address = tmp.address ? tmp.address.parseToObject() : null;
    tmp.contactPerson = tmp.contactPerson ? tmp.contactPerson.parseToObject() : null;
    tmp.cleanTimes = tmp.cleanTimes.slice();
    tmp.areas = tmp.areas.slice();
    tmp.tasks = tmp.tasks.slice();
    tmp.managers = tmp.managers.slice();
    tmp.machines = tmp.machines.slice();
    return tmp;
  }

  public get derivedAddress(): Address | undefined {
    return this.address ?? this.customer?.address;
  }
  public get derivedContactPerson(): ContactPerson | undefined {
    return this.contactPerson ?? this.customer?.contactPerson;
  }
}
