import Parseable from '@/misc/Parseable';
import WorkSession from '@/models/WorkSession';
import User from '@/models/User';
import Location from '@/models/Location';
import CleanTime from '@/models/CleanTime';
import ACTT from '@/models/ACTT';
import Customer from '@/models/Customer';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';

/**
 * A job represents the planning or the completed work of ACTTS from Users in Locations in a certain clean time.
 */
export default class Job extends Parseable {

    public static parseFromObject(object: Partial<Job>): Job {
        const job = new Job();

        object.cleanTime = CleanTime.parseFromObject(object.cleanTime!);
        object.location = Location.parseFromObject(object.location!);
        object.workSessions = WorkSession.parseFromArray(object.workSessions!) as WorkSession[];
        object.users = User.parseFromArray(object.cleanTime!.plannedUsers) as User[];
        Object.assign(job, object);
        return job;
    }

    // New Structure of the Job
    public users: User[] = [];
    public cleanTime!: CleanTime;
    public customer: Customer | undefined;
    public customerId?: string;
    public location!: Location;
    public cleanTimeOccurrence!: CleanTimeOccurrence;
    public workSessions: WorkSession[] = [];
    public queryDate ?: string = ''; // used to filter job in calendar week view

    public parseToObject(): any {
        const tmp: any = {...this};
        tmp.cleanTime = tmp.cleanTime.parseToObject();
        tmp.location = tmp.location.parseToObject();
        tmp.workSessions = tmp.workSessions.slice();
        tmp.users = tmp.users.slice();
        return tmp;
    }
}
