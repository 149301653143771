import Parseable from '@/misc/Parseable';

export default class Task extends Parseable {

    public static parseFromObject(object: Partial<Task>): Task {
        const task = new Task();
        Object.assign(task, object);
        return task;
    }

    public id!: string;
    public name!: string;
    public description?: string;
    public company?: string;
    public colorSchema?: string | null;
    public order!: number;

    public parseToObject(): any {
        return {...this};
    }
}
