import Parseable from '@/misc/Parseable';

export default class Machine extends Parseable {

    public static parseFromObject(object: Partial<Machine>): Machine {
        const machine = new Machine();
        Object.assign(machine, object);
        return machine;
    }

    public id!: string;
    public name?: string;
    public isOwn?: boolean;
    public description?: string;

    public parseToObject(): any {
        return {...this};
    }

}
