import Vuex, { StoreOptions } from 'vuex';
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import authStore from './stores/auth.store';
import companyStore from './stores/company.store';
import customerStore from '@/stores/customer.store';
import userStore from '@/stores/user.store';
import cleanTimeStore from '@/stores/cleanTime.store';
import jobStore from '@/stores/job.store';
import fileStore from '@/stores/file.store';

Vue.use(Vuex);

// set up persistence storage plugin
const vuexPersistence = new VuexPersistence({
    key: process.env.VUE_APP_TITLE,
    storage: window.localStorage,
    reducer: (state: any) => ({
        auth: {
            token: state.auth.token,
            user: state.auth.user,
            refreshToken: state.auth.refreshToken,
        },
        company: {
            activeCompany: state.company.activeCompany,
        },
    }),
    filter: (mutation) => ['auth/saveLogin', 'auth/saveUser', 'auth/clearLogin', 'company/activeCompany', 'company/reset'].includes(mutation.type),
});

// set up store
const store: StoreOptions<any> = {
    state: {},
    modules: {
        auth: {
            namespaced: true,
            ...authStore,
        },
        company: {
            namespaced: true,
            ...companyStore,
        },
        customer: {
            namespaced: true,
            ...customerStore,
        },
        user: {
            namespaced: true,
            ...userStore,
        },
        cleanTime: {
            namespaced: true,
            ...cleanTimeStore,
        },
        job: {
            namespaced: true,
            ...jobStore,
        },
        file: {
            namespaced: true,
            ...fileStore,
        },
    },
    plugins: [vuexPersistence.plugin],
    // strict: process.env.NODE_ENV !== 'production',
};
export default new Vuex.Store<any>(store);
