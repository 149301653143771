import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import JobRepository from '@/api/repositories/JobRepository';
import Job from '@/models/Job';
import WorkSession from '@/models/WorkSession';
import JobsFilterData from '@/misc/JobsFilterData';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import moment from 'moment';

const jobRepository: JobRepository = RepositoryFactory.get('job');

enum jobStoreState {
  JOBS_CACHE = 'jobsCache',
  JOBS = 'jobs',
  LOADING = 'loading',
}

const store = {
  /**
   * Cache for all jobs that were loaded
   */
  [jobStoreState.JOBS_CACHE]: {},
  /**
   * Jobs that are from a particular period of time. Loaded either fresh from backend or retrieved from cache.
   */
  [jobStoreState.JOBS]: [],
  /**
   * A flag that indicates if a api request for job retrieving is performed
   */
  [jobStoreState.LOADING]: false,
};

/**
 * The limitation of how many dates can be cached
 */
const JOB_CACHE_ENTRY_LIMIT = 365;

export enum jobStoreActions {
  CANCEL_REQUESTS_ACTION = 'cancelRequestsAction',
  LOAD_JOBS_ACTION = 'loadJobsAction',
  LOAD_WORK_SESSION_ACTION = 'loadWorkSessionAction',
  GET_WORK_SESSIONS_ACTION = 'getWorkSessionsAction',
  CREATE_WORK_SESSIONS_ACTION = 'createWorkSessionAction',
  UPDATE_WORK_SESSIONS_ACTION = 'updateWorkSessionAction',
  LOAD_WORK_SESSION_IMAGE_ACTION = 'loadWorkSessionImage',
}

const actions: ActionTree<any, any> = {
  [jobStoreActions.CANCEL_REQUESTS_ACTION]: async () => {
    jobRepository.cancelRequests();
  },
  /**
   * Loads jobs either from cache or from api based on the queryData. Jobs from api are cached in store and in normal jobs store.
   * @param commit
   * @param getters through named deconstruction accessible via realGetter. This was necessary to avoid breaching of no-shadow linter rule
   * @param payload
   */
  [jobStoreActions.LOAD_JOBS_ACTION]: async ({commit, getters: realGetter},
                                             payload: { filterData: JobsFilterData, discardCache?: boolean }): Promise<Job[]> => {
    commit(jobStoreMutations.STORE_LOADING, true);
    // check cache, if too high, clear jobs storage
    if (realGetter[jobStoreGetter.JOBS_CACHE_LENGTH] > JOB_CACHE_ENTRY_LIMIT || payload.discardCache) {
      commit(jobStoreMutations.CLEAR_CACHED_JOBS);
    }

    // Get Max Amount of Job Queries at the same time from
    // the environment, else use 8 as default
    const maxJobs = parseInt(`${process.env.VUE_APP_MAX_JOB_REQUESTS}`) || 8;

    // Get Query Data of Payload, and create a temporary array for holding our chunks
    const queryData: Array<{ date: string, query: string }> = payload.filterData.queryData;

    // Method which flattens an multidimensional array to a single dimension
    const getFlattenedJobArray = (resolvedJobs: Job[][]) => {
      let jobs: Job[] = [];
      for (const jobArray of resolvedJobs) {
        jobs = jobs.concat(Job.parseFromArray(jobArray) as Job[]);
      }
      return jobs;
    };

    // Method to Save Jobs in Cache
    const saveJobsInCache = (jobQueryData: Array<{ date: string, query: string }>, jobs: Job[][]) => {
      for (let i = 0; i < jobs.length; i++) {
        commit(jobStoreMutations.CACHE_JOBS, {[jobQueryData[i].date]: jobs[i]});
      }
    };

    // New method for loading jobs where, instead of loading in batches of 8, there are always 8 active requests.
    // Once one query is resolved, the next query is triggered.
    const promises: Array<{ promise: Promise<Job[]>, trigger: () => void }> = queryData.map((item) => {
      // Default trigger for when the requested date was already cached
      let trigger: () => void = () => triggerNext();

      // If jobs are cached, just return them with the default trigger that doesn't do anything except calling the
      // next trigger
      const cachedJobs = realGetter[jobStoreGetter.DATE_CACHED_JOBS](item.date);
      if (cachedJobs) {
        return {
          promise: cachedJobs,
          trigger,
        };
      }
      // If jobs are not cached, return a promise that resolves to the retrieved jobs after triggered and then caches
      // them immediately
      const promise: Promise<Job[]> = new Promise((resolve) => {
        trigger = async () => {
          const resolved = await jobRepository.loadJobs(item.query);
          if (Array.isArray( resolved )) {
            resolved.forEach((job) => {
              job.queryDate = item.date;
            });
            resolved.sort((a, b) => moment(a.cleanTimeOccurrence.start).diff(moment(b.cleanTimeOccurrence.start)));
          }
          resolve(resolved);
          triggerNext();
          saveJobsInCache([item], [resolved]);
        };
      });
      return {promise, trigger};
    });

    let nextQuery = 0;
    // If not all queries are resolved, trigger the next promise
    const triggerNext = () => {
      if (nextQuery < queryData.length) {
        promises[nextQuery++].trigger();
      }
    };

    // Trigger the first maxJobs queries to get things started
    for (let i = 0; i < maxJobs; i++) {
      triggerNext();
    }

    const flattenedJobs = getFlattenedJobArray(
      await Promise.all(
        promises.map((val) => val.promise),
      ),
    );

    commit(jobStoreMutations.STORE_JOBS, flattenedJobs);
    commit(jobStoreMutations.STORE_LOADING, false);
    return realGetter[jobStoreGetter.JOBS];
  },
  /**
   * Simple loading function to get a workSession. This session is not saved in store.
   * @param commit
   * @param workSessionId
   */
  [jobStoreActions.LOAD_WORK_SESSION_ACTION]: async ({commit}, workSessionId: string): Promise<WorkSession> => {
    const rawWorkSession = await jobRepository.loadWorkSession(workSessionId);
    return WorkSession.parseFromObject(rawWorkSession);
  },
  [jobStoreActions.LOAD_WORK_SESSION_IMAGE_ACTION]:
    async ({commit}, payload: { workSessionId: string, imageId: string, thumbnail: boolean }): Promise<{blob: Blob}> => {
      const workSessionImage = await jobRepository.getWorkSessionImage(payload);
      if (workSessionImage instanceof Blob) {
        return {
          blob: workSessionImage,
        };
      }
      return workSessionImage;
    },

  [jobStoreActions.GET_WORK_SESSIONS_ACTION]: async ({commit}, filterData: WorkSessionFilterData): Promise<WorkSession[]> => {
    // Create a query string from a map
    const mapFromStringArray = (array: string[], query: string) => {
      return array!.map((id) => `&${query}=${id}`).join('');
    };

    const customer = filterData.customers && filterData.customers.length > 0
      ? mapFromStringArray(filterData.customers, 'customerId')
      : '';

    const users = filterData.users && filterData.users.length > 0
      ? mapFromStringArray(filterData.users, 'userId')
      : '';

    const managers = filterData.managers && filterData.managers.length > 0
      ? mapFromStringArray(filterData.managers, 'manager')
      : '';

    const locations = filterData.locations && filterData.locations.length > 0
      ? mapFromStringArray(filterData.locations, 'locationId')
      : '';

    // Boolean for comments and images
    const hasComments = filterData.hasComments ? '&hasComments' : '';
    const hasImages = filterData.hasImages ? '&hasImages' : '';
    const hasDuration = filterData.hasDuration ? '&hasDuration' : '';
    const sort = filterData.sort ? '&sort=' + filterData.sort : '';

    // Time frame
    // TODO change this after backend merge of P22-443
    const endTimeAtFrom = `&createdAtFrom=${filterData.endTimeAtFrom}`;
    const endTimeAtTo = `&createdAtTo=${filterData.endTimeAtTo}`;

    // Create a query String from customers, locations, their managers and users
    const queryString = `?companyId=${filterData.company}${customer}${users}${locations}${managers}${hasComments}${hasImages}${hasDuration}${endTimeAtFrom}${endTimeAtTo}${sort}`;

    // Get all WorkSessions from api
    const rawWorkSession = await jobRepository.getWorkSessions(queryString);

    // return as array
    return WorkSession.parseFromArray(rawWorkSession.records) as WorkSession[];
  },
  [jobStoreActions.CREATE_WORK_SESSIONS_ACTION]: async ({commit}, workSession: WorkSession): Promise<WorkSession> => {
    return await jobRepository.createWorkSession(workSession);
  },
  [jobStoreActions.UPDATE_WORK_SESSIONS_ACTION]: async ({commit}, workSession: WorkSession): Promise<WorkSession> => {
    return await jobRepository.updateWorkSession(workSession);
  },
};

export enum jobStoreMutations {
  CACHE_JOBS = 'cacheJobs',
  STORE_JOBS = 'storeJobs',
  CLEAR_JOBS = 'clearJobs',
  CLEAR_CACHED_JOBS = 'clearCachedJobs',
  STORE_LOADING = 'storeLoading',
}

const mutations = {
  /**
   * Cached job with given date and notifies getter
   * @param state
   * @param payload
   */
  [jobStoreMutations.CACHE_JOBS]: (state: any, payload: { date: string, jobs: Job[] }) => {
    Object.assign(state[jobStoreState.JOBS_CACHE], payload);
    state[jobStoreState.JOBS_CACHE] = {...state[jobStoreState.JOBS_CACHE]}; // force notifying of getter
  },
  /**
   * Stores jobs
   * @param state
   * @param jobs
   */
  [jobStoreMutations.STORE_JOBS]: (state: any, jobs: Job[]) => state[jobStoreState.JOBS] = jobs,
  /**
   * Clears job storage
   * @param state
   */
  [jobStoreMutations.CLEAR_JOBS]: (state: any) => state[jobStoreState.JOBS] = [],
  /**
   * Clears jobsCache
   * @param state
   */
  [jobStoreMutations.CLEAR_CACHED_JOBS]: (state: any) => state[jobStoreState.JOBS_CACHE] = {},
  /**
   * Stores the loading state
   * @param state
   * @param value
   */
  [jobStoreMutations.STORE_LOADING]: (state: any, value: boolean) => state[jobStoreState.LOADING] = value,
};

export enum jobStoreGetter {
  JOBS_CACHE_LENGTH = 'jobsCacheLength',
  JOBS = 'jobs',
  DATE_CACHED_JOBS = 'dateCachedJobs',
  LOADING = 'loading',
}

const getters = {
  /**
   * Returns the jobs store entry length
   * @param state
   */
  [jobStoreGetter.JOBS_CACHE_LENGTH]: (state: any): number => Object.keys(state[jobStoreState.JOBS_CACHE]).length,
  /**
   * Returns all stored jobs
   * @param state
   */
  [jobStoreGetter.JOBS]: (state: any): Job[] => state[jobStoreState.JOBS],
  /**
   * Returns cached jobs for a given date
   * @param state
   */
  [jobStoreGetter.DATE_CACHED_JOBS]: (state: any) => (date: string): Job[] => state[jobStoreState.JOBS_CACHE][date],
  /**
   * Returns loading value
   * @param state
   */
  [jobStoreGetter.LOADING]: (state: any) => state[jobStoreState.LOADING],
};

const jobStore: Module<any, any> = {
  state: store,
  actions,
  mutations,
  getters,
};

export default jobStore;
