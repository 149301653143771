import {Permission} from '@/misc/enums/permission.enum';
import {VersionControl} from '@/misc/VersionControl';

/**
 * Defines a user role
 */
export default class UserRole extends VersionControl {

  public static parseFromObject(object: UserRole): UserRole {
    const role = new UserRole();
    Object.assign(role, object);
    return role;
  }

  public id!: string;
  public createdAt!: string;
  public updatedAt!: string;
  public deleted!: boolean;
  public vcOriginId?: string | null;
  public name!: string;
  public description: string = '';
  public global: boolean = false;
  public draft: boolean = true;
  public superAdmin: boolean = false;
  public tenantAdmin: boolean = false;
  public companyId: string = '';
  public permissions: Permission[] = [];
  public canBeLocationManager!: boolean;

  /**
   * `true` is role fulfils all requirements for global super admin role.
   */
  get isSuperAdmin() {
    return (
      // its global
      this.global &&
      // it's not draft
      !this.draft &&
      // it's super admin (obviously)
      this.superAdmin &&
      // and no tenant(system user)
      !this.companyId
    );
  }

  get isTenantAdmin() {
    return (
      // it's not global
      !this.global &&
      // it's not draft
      !this.draft &&
      // it's tenant admin (obviously)
      this.tenantAdmin &&
      // and a tenant
      !!this.companyId &&
      // it's no superAdmin
      !this.superAdmin
    );
  }

  public hasPermission(permission: Permission): boolean {
    return (
      this.permissions.includes(permission) ||
      this.isSuperAdmin ||
      this.isTenantAdmin
    );
  }

  /**
   * checks if the user has at least one of the defined permissions
   * @param permissions
   */
  public hasSomePermissions(permissions: Permission[]) {
    return (
      permissions.some((permission: Permission) =>
        this.permissions.includes(permission),
      ) ||
      this.isSuperAdmin ||
      this.isTenantAdmin
    );
  }

  public hasEveryPermission(permissions: Permission[]) {
    return (
      permissions.every((permission: Permission) =>
        this.permissions.includes(permission),
      ) ||
      this.isSuperAdmin ||
      this.isTenantAdmin
    );
  }

  public parseToObject(): any {
    return {...this};
  }
}
