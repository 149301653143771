import User from '@/models/User';
import Address from '@/models/Address';
import Task from '@/models/Task';
import {VersionControl} from '@/misc/VersionControl';
import UserRole from '@/models/user-attributes/UserRole';

/**
 * Simple modal for a company entity
 */
export default class Company extends VersionControl {
    // index signature
    [key: string]: string | boolean | number | User | undefined | User[] | UserRole[] | Task[] | (() => any) | Address |
        ((bool: boolean) => any);

    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: any): Company {
        const company = new Company();
        Object.assign(company, object);
        company.users = User.parseFromArray(object.users) as User[];
        company.tasks = Task.parseFromArray(object.tasks) as Task[];
        if (object.roles) {
            company.roles = UserRole.parseFromArray(object.roles) as UserRole[];
        }
        if (object.billingAddress) {
            company.address = Address.parseFromObject(object.billingAddress);
        }
        // The usersCount is not calculated at update
        company.usersCount = object.usersCount ?? object.users?.length;
        return company;
    }

    public id!: string;
    public name!: string;
    public address?: Address;
    public maxUsers!: number;
    public usersCount!: number;
    public customersCount?: number;
    public active!: boolean;
    public prefix!: string;

    public users: User[] = [];
    public roles: UserRole[] = [];
    public tasks: Task[] = [];

    constructor() {
        super();
        this.address = new Address();
    }


    /**
     * Returns the class object as plain object representation
     * RN-391 just a swallow copy ... maybe this is not enough...
     */
    public parseToObject(): Partial<Company> {
        const tmp: any = {...this};
        tmp.address = {...tmp.address};
        return tmp;
    }

    /**
     * Return different colors for the max employees state
     * @param forText Add --text to change the text-color
     */
    public getColorForUserState(forText: boolean): string {
        const percentage = (this.usersCount! / this.maxUsers) * 100;
        let color = '';

        if (percentage < 50) {
            color = 'green';
        } else if (percentage < 75) {
            color = 'bar-yellow';
        } else if (percentage < 90) {
            color = 'orange';
        } else {
            color = 'red';
        }

        if (forText) {
            color += '--text';
        }

        return color;
    }
}
