import {ActionTree, Module} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import CustomerRepository from '@/api/repositories/CustomerRepository';
import Area from '@/models/Area';
import CleanTime from '@/models/CleanTime';
import {RequestParams} from '@/interfaces/Responses';
import {parseRequestParams} from '@/helper/ParseRequestParams';

const customerRepository: CustomerRepository = RepositoryFactory.get('customer');

const store = {
    customers: [],
    locations: [],
    customer: undefined,
    location: undefined,
    areas: [],
    lastOverviewCalendarDate: '',
};

const actions: ActionTree<any, any> = {
    cancelRequestsAction: async () => {
        customerRepository.cancelRequests();
    },
    loadCustomersAction: async ({commit}, companyId: string): Promise<Customer[]> => {
        const customersRaw = await customerRepository.loadCustomers(companyId);
        const customers = Customer.parseFromArray(customersRaw.records) as Customer[];
        commit('storeCustomers', customers);
        return customers;
    },
    loadCustomerAction: async ({commit}, payload: {customerId: string, store: boolean} | string) => {
        let customerId = payload;
        let storeCustomer: boolean = true;
        if (typeof customerId === 'object') {
            storeCustomer = customerId.store;
            customerId = customerId.customerId;
        }
        const customerRaw = await customerRepository.loadSingleCustomer(customerId);
        const customer = Customer.parseFromObject(customerRaw);
        if (storeCustomer) {
            commit('storeCustomer', customer);
        }
        return customer;
    },
    loadCustomerOrigin: async ({commit}, customerId: string, skip?: number, limit?: number) => {
        const customersRaw = await customerRepository.loadCustomerVc(customerId, skip, limit);
        const customers = Customer.parseFromArray(customersRaw.records);
        commit('storeCustomers', customers);
        return customers;
    },
    createCustomerAction: async ({commit}, customer) => {
        const customerRaw = await customerRepository.create(customer);
        const createdCustomer = Customer.parseFromObject(customerRaw);
        commit('updateCustomers', createdCustomer);
        return createdCustomer;
    },
    deleteCustomerAction: async ({commit}, customer) => {
        await customerRepository.delete(customer);
        commit('updateCustomers', customer);
        return customer;
    },
    editCustomerAction: async ({commit}, customer: Customer) => {
        // use copy and delete associated values
        const customerCopy = customer.parseToObject();
        delete customerCopy.locations;
        delete customerCopy.company;

        const customerRaw = await customerRepository.edit(customerCopy);
        const editedCustomer = Customer.parseFromObject(customerRaw);
        commit('updateCustomers', editedCustomer);
        return editedCustomer;
    },
    createLocationAction: async ({commit}, payload: { location: Location, shouldBeStored: boolean }): Promise<Location> => {
        const locationRaw = await customerRepository.createLocation(payload.location);
        const createdLocation = Location.parseFromObject(locationRaw);
        commit('addLocation', createdLocation);
        if (payload.shouldBeStored) {
            commit('storeLocation', createdLocation);
        }
        return createdLocation;
    },
    editLocationAction: async ({commit, dispatch}, payload: { location: Location, shouldBeStored: boolean }): Promise<Location> => {
        // use copy and delete associated values
        const locationCopy = payload.location.parseToObject();
        delete locationCopy.cleanTimes;
        delete locationCopy.tasks;
        delete locationCopy.areas;
        delete locationCopy.customer;

        const locationRaw = await customerRepository.editLocation(locationCopy);
        const editedLocation = Location.parseFromObject(locationRaw);
        if (payload.shouldBeStored) {
            commit('storeLocation', editedLocation);
        }
        return editedLocation;
    },
    deleteLocationAction: async ({commit}, location: Location) => {
        await customerRepository.deleteLocation(location);
        commit('removeLocation', location);
        return location;
    },
    loadLocationAction: async ({commit}, payload: { locationId: string, shouldBeStored: boolean }): Promise<Location> => {
        const locationRaw = await customerRepository.loadLocation(payload.locationId);
        const location = Location.parseFromObject(locationRaw);
        if (payload.shouldBeStored) {
            commit('storeLocation', location);
            commit('storeAreas', location.areas);
        }
        return location;
    },
    loadLocationOrigin: async ({commit}, locationId: string, skip?: number, limit?: number): Promise<Location[]> => {
        return (await customerRepository.loadLocationVc(locationId, skip, limit)).records;
    },
    loadLocationsAction: async ({commit}, payload: RequestParams<Location>): Promise<Location[]> => {
        const locationsRaw = await customerRepository.loadCustomersLocations(parseRequestParams(payload));
        const retVal = Location.parseFromArray(locationsRaw.records) as Location[];
        commit('storeLocations', retVal);
        return  retVal;
    },
    createAreaAction: async ({commit}, area: Area): Promise<any> => {
        const areaRaw = await customerRepository.createArea(area);
        const createdArea = Area.parseFromObject(areaRaw);
        commit('storeArea', createdArea);
        return Area.parseFromObject(createdArea);
    },
    deleteAreaAction: async ({commit}, areaId: string): Promise<any> => {
        await customerRepository.deleteArea(areaId);
        commit('removeArea', areaId);
    },
};

const mutations = {
    storeCustomers: (state: any, customers: Customer[]) => state.customers = customers,
    updateCustomers: (state: any, customer: Customer) => {
        const index = state.customers.findIndex((item: Customer) => item.id === customer.id);
        if (index >= 0) {
            state.customers.splice(index, 1, customer);
        } else {
            state.customers.push(customer);
        }
    },
    storeCustomer: (state: any, customer: Customer) => state.customer = customer,
    addLocation: (state: any, location: Location) => state.customer.locations.push(location),
    storeLocation: (state: any, location: Location) => state.location = location,
    storeLocations: (state: any, locations: Location[]) => state.locations = locations,
    addCleanTime: (state: any, cleanTime: CleanTime) => state.location.cleanTimes.push(cleanTime),
    removeLocation: (state: any, location: Location) => {
        const index = state.customer.locations.findIndex((item: Location) => item.id === location.id);
        state.customer.locations.splice(index, 1);
    },
    storeAreas: (state: any, areas: Area[]) => state.areas = areas,
    storeArea: (state: any, area: Area) => state.areas.push(area),
    removeArea: (state: any, areaId: string) => {
        const index = state.areas.findIndex((item: Area) => item.id === areaId);
        state.areas.splice(index, 1);
    },
    setDate: (state: any, date: string) => state.lastOverviewCalendarDate = date,
};

const getters = {
    customers: (state: any) => state.customers,
    locations: (state: any) => state.locations,
    customer: (state: any) => state.customer,
    location: (state: any) => state.location,
    areas: (state: any) => state.areas,
    lastOverviewCalendarDate: (state: any) => state.lastOverviewCalendarDate,
};

const customerStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default customerStore;
