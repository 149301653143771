import Parseable from '@/misc/Parseable';
import User from '@/models/User';
import Customer from '@/models/Customer';
import Timesheet from '@/models/Timesheet';

export type FileEntities = 'users' | 'locations' | 'customers' | 'timesheets';

export interface Label {
  id?: string;
  name: string;
  companyId: string;
}

export default class DbFile extends Parseable {
  public static parseFromObject(object: Partial<DbFile>): DbFile {
    const file = new DbFile();
    Object.assign(file, object);
    return file;
  }

  public id!: string;

  public data!: Buffer;

  public name!: string;

  public user?: User | null;
  public userId?: string | null;
  public locationId?: string | null;
  public location?: string | null;
  public customerId?: string | null;
  public customer?: Customer | null;
  public timesheetId?: string | null;
  public timesheet?: Timesheet | null;
  public label?: string | null;

  constructor(props?: Partial<DbFile>) {
    super();
    if (!props) {
      props = {
        name: '',
      };
    } else {
      props.name = props.name ?? '';
    }
    Object.assign(this, props);
  }

  public parseToObject(): Partial<DbFile> {
    return {...this};
  }
}
