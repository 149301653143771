import { ActionTree, Module } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import UserRepository from '@/api/repositories/UserRepository';
import UserRole from '@/models/user-attributes/UserRole';
import User from '@/models/User';
import Company from '@/models/Company';
import TimesheetRepository from '@/api/repositories/TimesheetRepository';
import Timesheet from '@/models/Timesheet';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import Denomination from '@/models/user-attributes/Denomination';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import PaymentType from '@/models/user-attributes/PaymentType';
import Gender from '@/models/user-attributes/Gender';
import {KeyOf} from '@/misc/Parseable';

const userRepository: UserRepository = RepositoryFactory.get('user');
const timesheetRepository: TimesheetRepository = RepositoryFactory.get('timesheet');

const store = {
    activeUser: undefined,
    users: [],
    roles: [],
    employmentTypes: [],
    denominations: [],
    billingDeliveries: [],
    paymentTypes: [],
    gender: [],
};

const actions: ActionTree<any, any> = {
    loadUsersAction: async ({commit}, payload: {companyId: string, populate?: KeyOf<User>[], options?: any}): Promise<User[]> => {
        // Any string with length > 1 forces the queryOptions to have a 'true' value for the backend
        const queryOptions = {
            availableAtCleanTimes: payload.options?.availableAtCleanTimes ? payload.options.availableAtCleanTimes : '',
        };
        const usersRaw = await userRepository.loadUsers(payload.companyId, payload.populate, queryOptions);
        const users = User.parseFromArray(usersRaw.records) as User[];
        commit('storeUsers', users);
        return users;
    },
    loadUserOrigin: async ({commit}, userId: string, skip?: number, limit?: number) => {
        const usersRaw = await userRepository.loadUserVc(userId, skip, limit);
        return User.parseFromArray(usersRaw.records);
    },
    loadUserAction: async ({commit, state}, userId: string) => {
        const userRaw = await userRepository.loadSingleUser(userId);
        const user = User.parseFromObject(userRaw);
        commit('storeActiveUser', user);
        return user;
    },
    loadUserByEmailAction: async ({commit, state}, email: string) => {
        const userRaw = await userRepository.loadUserByEmail(email);
        const users = User.parseFromArray(userRaw) as User[];
        commit('storeUsers', users);
        return users;
    },
    loadUserTimesheetAction: async ({commit, state}, userId: string) => {
        const timesheetRaw = await timesheetRepository.loadUserTimesheet(userId);
        return Timesheet.parseFromArray(timesheetRaw.records) as Timesheet[];
    },
    createUserAction: async ({commit}, user: User): Promise<User> => {
        user.roleId = user.role?.id ?? user.roleId;
        const rawUser = await userRepository.createUser(user);
        const createdUser = User.parseFromObject(rawUser);
        commit('storeUser', createdUser);
        return createdUser;
    },
    deleteUserAction: async ({commit}, user) => {
        await userRepository.deleteUser(user);
        commit('updateUsers', user);
        return user;
    },
    editUserAction: async ({commit}, user: User): Promise<User> => {
        const userCopy = user.parseToObject();

        // prepare user object for patch
        userCopy.roleId = (userCopy.role! as UserRole).id;

        if (userCopy.company) {
            userCopy.companyId = (userCopy.company! as Company).id;
        }

        delete userCopy.cleanTimes;

        const rawUpdatedUser = await userRepository.updateUser(userCopy);
        const updatedUser = User.parseFromObject(rawUpdatedUser);
        commit('storeActiveUser', updatedUser);
        return updatedUser;
    },
    setUserStatusAction: async ({commit}, payload: { id: string, active: boolean, preview?: boolean, force?: boolean }): Promise<User> => {
        const rawUpdatedUser = await userRepository.setUserStatus(payload);
        const updatedUser = User.parseFromObject(rawUpdatedUser);
        commit('storeActiveUser', updatedUser);
        return updatedUser;
    },
    loadUsersAttributesAction: async ({commit}): Promise<any> => {
        const attributes = await userRepository.loadUserAttributes();
        commit('saveUserGender', attributes.gender);
        commit('saveUserEmploymentType', attributes.employmentType);
        commit('saveUserDenomination', attributes.denomination);
        commit('saveUserBillingDelivery', attributes.billingDelivery);
        commit('saveUserPaymentType', attributes.paymentType);
        return attributes;
    },
    loadTimesheetAction: async ({commit}, timesheetId: string): Promise<Blob> => {
        return timesheetRepository.loadTimesheet(timesheetId);
    },
    changePasswordAction: async ({commit}, payload: { user: User, passwordOld: string, password: string }): Promise<void> => {
        await userRepository.changePassword(payload);
    },
    resendInvitationAction: async ({commit}, user: User): Promise<void> => {
        await userRepository.resendInvitation(user.id!);
    },
    loadUserRolesAction: async ({state, commit}, payload: {companyId: string, populate: string[]}): Promise<UserRole[]> => {
        // check if cached roles are available
        const cachedRoles = getters.roles(state);
        if (cachedRoles.length > 0) {
            return cachedRoles;
        } else {
            const rawRoles = await userRepository.loadUserRoles(payload.companyId, payload.populate);
            const roles = UserRole.parseFromArray(rawRoles.records);
            commit('saveUserRoles', roles);
            return roles as UserRole[];
        }
    },
    createUserRoleAction: async ({commit}, role: UserRole): Promise<UserRole> => {
        let res = await userRepository.createNewUserRole(role);
        res = UserRole.parseFromObject(res);
        return res;
    },
    deleteUserRoleAction: async ({commit}, id: string): Promise<any> => {
        await userRepository.deleteUserRole(id);
    },
    updateUserRoleAction: async ({commit}, role: UserRole): Promise<UserRole> => {
        let res = await userRepository.updateUserRole(role);
        res = UserRole.parseFromObject(res);
        return res;
    },
};

const mutations = {
    storeUsers: (state: any, users: User[]) => state.users = users,
    storeActiveUser: (state: any, user: User) => state.activeUser = user,
    storeUser: (state: any, user: User) => {
        const index = state.users.findIndex((item: User) => item.id === user.id);
        if (index >= 0) {
            state.users.splice(index, 1, user);
        } else {
            state.users.push(user);
        }
    },
    saveUserRoles: (state: any, payload: any) => state.roles = payload,
    saveUserGender: (state: any, payload: any) => state.gender = payload,
    saveUserEmploymentType: (state: any, payload: any) => state.employmentTypes = payload,
    saveUserDenomination: (state: any, payload: any) => state.denominations = payload,
    saveUserBillingDelivery: (state: any, payload: any) => state.billingDeliveries = payload,
    saveUserPaymentType: (state: any, payload: any) => state.paymentTypes = payload,
};

const getters = {
    users: (state: any) => state.users,
    activeUser: (state: any) => state.activeUser,
    roles: (state: any): UserRole[] => state.roles,
    employmentTypes: (state: any): EmploymentType[] => state.employmentTypes,
    denominations: (state: any): Denomination[] => state.denominations,
    billingDeliveries: (state: any): BillingDelivery[] => state.billingDeliveries,
    paymentTypes: (state: any): PaymentType[] => state.paymentTypes,
    gender: (state: any): Gender[] => state.gender,
};

const userStore: Module<any, any> = {
    state: store,
    actions,
    mutations,
    getters,
};

export default userStore;
