export enum Permission {
    COMPANY_READ = 'COMPANY_READ',
    COMPANY_READ_OWN = 'COMPANY_READ_OWN',
    COMPANY_CREATE = 'COMPANY_CREATE',
    COMPANY_UPDATE = 'COMPANY_UPDATE',
    COMPANY_UPDATE_OWN = 'COMPANY_UPDATE_OWN',
    COMPANY_DELETE = 'COMPANY_DELETE',


    CUSTOMER_READ = 'CUSTOMER_READ',
    CUSTOMER_READ_OWN = 'CUSTOMER_READ_OWN',
    CUSTOMER_CREATE = 'CUSTOMER_CREATE',
    CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
    CUSTOMER_UPDATE_OWN = 'CUSTOMER_UPDATE_OWN',
    CUSTOMER_DELETE = 'CUSTOMER_DELETE',
    CUSTOMER_DELETE_OWN = 'CUSTOMER_DELETE_OWN',


    LOCATION_READ = 'LOCATION_READ',
    LOCATION_READ_OWN = 'LOCATION_READ_OWN',
    LOCATION_CREATE = 'LOCATION_CREATE',
    LOCATION_UPDATE = 'LOCATION_UPDATE',
    LOCATION_UPDATE_OWN = 'LOCATION_UPDATE_OWN',
    LOCATION_DELETE = 'LOCATION_DELETE',
    LOCATION_DELETE_OWN = 'LOCATION_DELETE_OWN',

    ROLE_READ = 'ROLE_READ',
    ROLE_READ_OWN = 'ROLE_READ_OWN',
    ROLE_CREATE = 'ROLE_CREATE',
    ROLE_UPDATE = 'ROLE_UPDATE',
    ROLE_DELETE = 'ROLE_DELETE',


    USER_READ = 'USER_READ',
    USER_READ_OWN = 'USER_READ_OWN',
    USER_CREATE = 'USER_CREATE',
    USER_UPDATE = 'USER_UPDATE',
    USER_UPDATE_OWN = 'USER_UPDATE_OWN',
    USER_DELETE = 'USER_DELETE',
    USER_DELETE_OWN = 'USER_DELETE_OWN',


    TIMESHEET_READ = 'TIMESHEET_READ',
    TIMESHEET_READ_OWN = 'TIMESHEET_READ_OWN',
    TIMESHEET_CREATE = 'TIMESHEET_CREATE',
    TIMESHEET_UPDATE = 'TIMESHEET_UPDATE',
    TIMESHEET_UPDATE_OWN = 'TIMESHEET_UPDATE_OWN',
    TIMESHEET_DELETE = 'TIMESHEET_DELETE',
    TIMESHEET_DELETE_OWN = 'TIMESHEET_DELETE_OWN',


    CLEANTIME_READ = 'CLEANTIME_READ',
    CLEANTIME_READ_OWN = 'CLEANTIME_READ_OWN',
    CLEANTIME_CREATE = 'CLEANTIME_CREATE',
    CLEANTIME_UPDATE = 'CLEANTIME_UPDATE',
    CLEANTIME_UPDATE_OWN = 'CLEANTIME_UPDATE_OWN',
    CLEANTIME_DELETE = 'CLEANTIME_DELETE  ',
    CLEANTIME_DELETE_OWN = 'CLEANTIME_DELETE_OWN',


    WORKSESSION_READ = 'WORKSESSION_READ',
    WORKSESSION_READ_OWN = 'WORKSESSION_READ_OWN',
    WORKSESSION_CREATE = 'WORKSESSION_CREATE',
    WORKSESSION_UPDATE = 'WORKSESSION_UPDATE',
    WORKSESSION_UPDATE_OWN = 'WORKSESSION_UPDATE_OWN',
    WORKSESSION_DELETE = 'WORKSESSION_DELETE',
    WORKSESSION_DELETE_OWN = 'WORKSESSION_DELETE_OWN',

    FILE_READ = 'FILE_READ',
    FILE_READ_OWN = 'FILE_READ_OWN',
    FILE_CREATE = 'FILE_CREATE',
    FILE_UPDATE = 'FILE_UPDATE',
    FILE_UPDATE_OWN = 'FILE_UPDATE_OWN',
    FILE_DELETE = 'FILE_DELETE',
    FILE_DELETE_OWN = 'FILE_DELETE_OWN',
}

export const PermissionNull: string = '-';
export const PermissionTypes: string[] = new Array(
    ...new Set(
        Object.values(Permission)
            .map((value: string) => value.split('_')[0])));

export const PermissionObjects = PermissionTypes.map((type: string) => ({
    name: type,
    desc: 'DESCRIPTION.' + type,
}));

export const PermissionLevels: string[] = [
    'READ' ,
    'CREATE',
    'UPDATE',
    'DELETE',
];
