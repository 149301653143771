import Address from '@/models/Address';
import Company from '@/models/Company';
import ContactPerson from '@/models/ContactPerson';
import Location from '@/models/Location';
import {VersionControl} from '@/misc/VersionControl';

export default class Customer extends VersionControl {

    /**
     * Turns a plain object to a class object
     * @param object
     */
    public static parseFromObject(object: Partial<Customer>): Customer {
        const customer = new Customer();
        object.address = Address.parseFromObject(object.address!);
        object.contactPerson = ContactPerson.parseFromObject(object.contactPerson!);
        if (object.invoiceAddress) {
            object.invoiceAddress = Address.parseFromObject(object.invoiceAddress!);
        }
        object.locations = Location.parseFromArray(object.locations!) as Location[];
        Object.assign(customer, object);
        return customer;
    }

    public id?: string;
    public name?: string;
    public active?: boolean;
    public company?: Company;
    public address?: Address;
    public contactPerson?: ContactPerson;
    public addressId?: string;
    public invoiceAddressId?: string | null;
    public contactPersonId?: string;
    public deleted!: boolean;
    public vcOriginId?: string | null;
    public companyId!: string;


    public invoiceAddress?: Address | null;

    public locations: Location[] = [];
    public locationsCount!: number;
    public locationsCountActive!: number;

    public createdAt!: Date;

    constructor() {
        super();
        this.address = new Address();
        this.contactPerson = new ContactPerson();
    }

    /**
     * Return a swallow object copy
     */
    public copy(): Customer {
        return Customer.parseFromObject(this.parseToObject());
    }

    // RN-389 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<Customer> {
        const tmp: any = {...this};
        tmp.address = {...tmp.address};
        tmp.invoiceAddress = tmp.invoiceAddress ? {...tmp.invoiceAddress} : null;
        tmp.contactPerson = {...tmp.contactPerson};
        tmp.locations = tmp.locations.slice();
        return tmp;
    }
}
