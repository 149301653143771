import ContactPerson from '@/models/ContactPerson';
import Signature from '@/models/Signature';

export default class Timesheet extends ContactPerson {

    public static parseFromObject(object: Partial<Timesheet>): Timesheet {
        const timesheet = new Timesheet();
        if (object.employeeSignature) {
            object.employeeSignature = Signature.parseFromObject(object.employeeSignature);
        }
        Object.assign(timesheet, object);
        return timesheet;
    }

    // general attributes
    public year!: number;
    public month!: number;
    public workSessionsCount!: number;
    public totalWorkTime!: number;
    public totalWorkTimeHumanized!: {[key: string]: string};
    public employeeSignature?: Signature;

    constructor() {
        super();
    }

    // RN-387 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<Timesheet> {
        const tmp: any = {...this};
        return tmp;
    }
}
