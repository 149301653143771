import Parseable from '@/misc/Parseable';
import {LineUtil} from 'leaflet';
import simplify = LineUtil.simplify;

export default class Signature extends Parseable {

  private SIMPLIFY_FACTOR: number = .7;

  public static parseFromObject(object: Partial<Signature>): Signature {
    const signature = new Signature();
    Object.assign(signature, object);
    return signature;
  }

  public id!: string;
  public data!: string;
  public createdAt!: string; // describes when the signature was made (in frontend referred as "letzte Bestätigung")

  /**
   * Return the string path as an array path and with further information about the dimension. The structure is as follows:
   * ...
   * data:
   *  [ // segments array
   *      [ // segment
   *          {x: 12, y: 23}, // points
   *           {x: 4, y: 11},
   *          ...  // further points
   *      ],
   *       [ // segment
   *           {x: 23, y: 4}, // points
   *           {x: 12, y: 10},
   *          ... // further points
   *      ],
   *      ... // further segments
   *  ]
   *
   *  Be aware that the returned path is simplified!! http://mourner.github.io/simplify-js/
   *  Polyline simplification dramatically reduces the number of points in a polyline while retaining its shape,
   *  giving a huge performance boost when processing it and also reducing visual noise.
   */
  public get pathData(): {
    width: number,
    height: number,
    xOffset: number,
    yOffset: number,
    data: Array<Array<{ x: number, y: number }>>,
  } {
    const dimensions = {
      minX: 1000,
      maxX: 0,
      minY: 1000,
      maxY: 0,
    };
    const pathData: any = {
      data: null,
      width: 0,
      height: 0,
      yOffset: 0,
      xOffset: 0,
    };

    // If no Signature data is available return with empty object
    if (!this.data) {
      return pathData;
    }

    pathData.data = this.data
      .split('x')
      .map((segment) => {
        const pathRaw = segment.split(';')
          .map((part) => {
              // convert array values into x and y object
              const coordsObject: any = {};
              [coordsObject.x, coordsObject.y] = part.split(',')
                .map((coordRaw, index) => {
                  // parse string to float
                  const coord = parseFloat(coordRaw);
                  // determine max dimension value to determine canves dimension
                  if (index === 0) { // x
                    if (coord < dimensions.minX) {
                      dimensions.minX = coord;
                    } else if (coord > dimensions.maxX) {
                      dimensions.maxX = coord;
                    }
                  } else { // y
                    if (coord < dimensions.minY) {
                      dimensions.minY = coord;
                    } else if (coord > dimensions.maxY) {
                      dimensions.maxY = coord;
                    }
                  }
                  return coord;
                });
              return coordsObject;
            },
          );
        return simplify(pathRaw, this.SIMPLIFY_FACTOR); // for further information take a look at: http://mourner.github.io/simplify-js/
      });

    // set remaining path data
    pathData.xOffset = dimensions.minX;
    pathData.yOffset = dimensions.minY;
    pathData.width = dimensions.maxX - dimensions.minX;
    pathData.height = dimensions.maxY - dimensions.minY;

    return pathData;
  }

  public parseToObject(): Partial<Signature> {
    return {...this};
  }
}
