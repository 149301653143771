import AuthBaseRepository from '@/api/repositories/AuthBaseRepository';
import {AuthResponse} from '@/interfaces/Responses';

/**
 *  Auth repository that provides authentication api methods
 */
export default class AuthRepository extends AuthBaseRepository {

    public async login(payload: object): Promise<AuthResponse> {
        return this.axiosClient.post('login', payload);
    }

    public async validateHash(hash: string): Promise<void> {
        return this.axiosClient.post(`setPassword/${hash}?validate`);
    }

    public async setPassword(payload: { hash: string, password: string }): Promise<AuthResponse> {
        return this.axiosClient.post(`setPassword/${payload.hash}`, {password: payload.password});
    }

    public async resetPassword(login: string): Promise<void> {
        return this.axiosClient.post(`resetPassword`, {login});
    }

    public async refreshToken(token: string): Promise<AuthResponse> {
        return this.axiosClient.post('refreshToken', { refreshToken: token });
    }
}
