/**
 * A plugin which enables theme respecting color accessing via the getThemeColor function
 * ATTENTION: In case if you wondering why the colors which are retrieved via getThemeColor are not changed during a theme change,
 * this is because the getThemeColor function is only called once and is not called again after a theme change. The components in which
 * the function is used need to do this.
 */
const ColorHandler = {
    install(Vue: any, vuetify: any) {
        /**
         * Returns a theme color value according to the current theme
         * @param key - theme color key
         */
        const getThemeColorFn = (key: string): string => {
            if (vuetify.framework.theme.dark) {
                return vuetify.framework.theme.themes.dark[key] as string;
            } else {
                return vuetify.framework.theme.themes.light[key] as string;
            }
        };

        const colorHandlerFns = {
            getThemeColor: getThemeColorFn,
        };

        Vue.prototype.$colorHandler = colorHandlerFns;
        Vue.colorHandler = colorHandlerFns;
    },
};
export default ColorHandler;
