import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import CustomNotifications from '@/plugins/custom-notifications/CustomNotifications';
import UserRoleHandler from '@/plugins/user-role-handler/UserRoleHandler';
import vuetify from '@/plugins/vuetify/vuetify';
import './filters';

import 'leaflet/dist/leaflet.css';
// import global scss
import '@/styles/styles.scss';
import moment from 'moment';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import ColorHandler from '@/plugins/color-handler/ColorHandler';

// --  misc
// - moment
// @ts-ignore
// this property is not defined in typings but exists anyway
moment.suppressDeprecationWarnings = true;
moment.locale('de'); // RN-377 make dynamic changes possible

Vue.use(CustomNotifications);
Vue.use(UserRoleHandler);
Vue.use(ColorHandler, vuetify);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
