import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import {FindAllResponse} from '@/interfaces/Responses';
import Timesheet from '@/models/Timesheet';

export default class TimesheetRepository extends EntityBaseRepository {

    public loadUserTimesheet(userId: string): Promise<FindAllResponse<Timesheet>> {
        return this.axiosClient.get(`timesheet?userId=${userId}`);
    }

    public loadTimesheet(timesheetId: string): Promise<Blob> {
        return this.axiosClient.get(`timesheet/${timesheetId}/pdf`, { responseType: 'blob' });
    }
}
