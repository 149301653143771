/**
 *  These theme colors properties are a mix from material design specs:
 *  https://material.io/design/color/#color-theme-creation
 *  and from vuetify theme management:
 * https://vuetifyjs.com/en/customization/theme#theme
 */
export default {
    // vuetify theme colors
    'primary': '#3787f6',
    'secondary': '#F5F5F5',
    'accent': '#ffffff', // RN-384 set accent color
    'error': '#bf2828',
    'info': '#3787f6',
    'success': '#92d048',
    'warning': '#FFC107',
    'disabled': '#e0e0e0',
    // material design specs colors
    // -- general
    'background': '#f3f3f3',
    'status-badge-background': '#ffffff',
    'surface': '#3c3f4a',
    // -- font
    'on-primary': '#ffffff',
    'on-secondary': '#313131',
    'on-background': '#3c3f4a',
    'on-surface': '#ffffff',
    'on-surface-variant': '#787b82',
};
