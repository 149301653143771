import { Vue } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import i18n from '@/i18n';

/**
 * Global filters functions are defined here
 */

/**
 * Converts an ISO date string to a locale date. The locale date is retrieved from i18n.locale
 */
Vue.filter('toDate', (value: string | Moment, format: string = 'GENERAL.DATE_FORMATS.DATE') => {
    if (!value) {
        return '';
    }
    const time = moment(value, moment.ISO_8601, true);
    if (!time.isValid()) {
        return value;
    }
    return time.format(i18n.t(format).toString());
});

/**
 * Converts an ISO date string to a locale date time. The locale date is retrieved from i18n.locale
 */
Vue.filter('toDateTime', (value: string) => {
    if (!value) {
        return '';
    }
    const time = moment(value, moment.ISO_8601, true);
    if (!time.isValid()) {
        return value;
    }
    return time.format(i18n.t('GENERAL.DATE_FORMATS.DATE_TIME').toString());
});

/**
 * Converts an zone respecting date string to HH:mm time string
 */
Vue.filter('toZoneTime', (value: string | Moment) => {
    if (!value) {
        return '';
    }
    const time = moment(value, moment.ISO_8601, true);
    if (!time.isValid()) {
        return value;
    }
    return time.format(i18n.t('GENERAL.DATE_FORMATS.TIME').toString());
});

/**
 * Converts an ISO date string to HH:mm time string
 */
Vue.filter('toTime', (value: string | Moment) => {
    if (!value) {
        return '';
    }
    const time = moment.utc(value, moment.ISO_8601, true);
    if (!time.isValid()) {
        return value;
    }
    return time.format(i18n.t('GENERAL.DATE_FORMATS.TIME').toString());
});

/**
 * Converts a HH:mm string or a timestamp to an ISO date string
 */
Vue.filter('fromTime', (value: string | number) => {
    const type = typeof value;
    switch (type) {
        case 'number':
            return moment.utc(value).format();
            break;
        case 'string':
            value = value as string;
            if (!value) {
                return '';
            }
            const timeRegEx = new RegExp('^\\d\\d:\\d\\d$');
            if (!timeRegEx.test(value)) {
                return value;
            }
            const timeParts = value.split(':');
            return moment.utc(0).add(timeParts[0], 'h').add(timeParts[1], 'm').format();
    }
});

/**
 * Returns a duration in milliseconds to a friendly duration representation HH:mm
 */
Vue.filter('toDuration', (value: number, breakLine: boolean = false) => {
    const duration = moment.duration(value);

    let hours = Math.floor(duration.asHours());
    let minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    if (seconds >= 30) {
        minutes++;
    }
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }
    return [hours, minutes]
        .map((x, i) => {
            switch (i) {
                case 0:
                    return `${x} ${i18n.t('GENERAL.UNITS.ABBRV.HOUR')} ${breakLine ? '<br />' : ''}`;
                case 1:
                    return `${x} ${i18n.t('GENERAL.UNITS.ABBRV.MINUTES')}`;
            }
        })
        .filter((str) => !!str)
        .join(' ');
});

/**
 * Returns a duration in milliseconds to a friendly duration representation HH:mm:ss
 */
Vue.filter('toDurationWithSeconds', (value: number) => {
    const duration = moment.duration(value);

    let hours = Math.floor(duration.asHours());
    let minutes = Math.floor(duration.minutes());
    let seconds = Math.floor(duration.seconds());

    if (seconds === 60) {
        seconds = 0;
        minutes++;
    }
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }
    return [hours, minutes, seconds]
      .map((x, i) => {
          switch (i) {
              case 0:
                  return `${x} ${i18n.t('GENERAL.UNITS.ABBRV.HOUR')} <br />`;
              case 1:
                  return `${x} ${i18n.t('GENERAL.UNITS.ABBRV.MINUTES')} <br />`;
              case 2:
                  return `${x} ${i18n.t('GENERAL.UNITS.ABBRV.SECONDS')}`;
          }
      })
      .filter((str) => !!str)
      .join(' ');
});

/**
 * Converts the first letter of the string to uppercase
 */
Vue.filter('capitalizeFirst', (value: string) => {
    if (!value) {
        return '';
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
