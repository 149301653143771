import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import Company from '@/models/Company';
import {FindAllResponse} from '@/interfaces/Responses';

/**
 * Repository that provides all methods related to company entity
 */
export default class CompanyRepository extends EntityBaseRepository {

    public loadCompanies(): Promise<FindAllResponse<Company>> {
        return this.axiosClient.get('company');
    }

    public loadCompany(id: string): Promise<Company> {
        return this.axiosClient.get(`company/${id}?populate=roles`);
    }
    // TODO add skip and limit
    public loadCompanyVc(id: string, skip?: number, limit?: number): Promise<FindAllResponse<Company>> {
        return this.axiosClient.get(`company?vcOriginId=${id}&vcIsLogEntity=true&populate=vcAuthor`);
    }

    public create(company: Company): Promise<Company> {
        return this.axiosClient.post('company', company);
    }

    public edit(company: Partial<Company>): Promise<Company> {
        return this.axiosClient.patch(`company/${company.id}`, company);
    }

}
