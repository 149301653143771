import Vue from 'vue';
import Router from 'vue-router';
import {RoutingHelper} from '@/helper/RoutingHelper';
import {Permission} from '@/misc/enums/permission.enum';

Vue.use(Router);

// Handle NavigationDuplicated stack error:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;
// @ts-ignore
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
// @ts-ignore
    return originalPush.call(this, location).catch((err) => err);
};

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'setPassword',
            path: '/setPassword/:hash?',
            component: () => import(/* webpackChunkName: "SetPasswordView" */ './views/general/SetPassword.view.vue'),
        },
        {
            name: 'login',
            path: '/login',
            beforeEnter: RoutingHelper.checkLoginAccess,
            component: () => import(/* webpackChunkName: "LoginView" */ './views/general/Login.view.vue'),
        },
        // {
        //     name: 'adminDashboard',
        //     path: '/admin/dashboard/:companyId?',
        //     beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, 'ADMIN_DASHBOARD'),
        //     component: () => import(/* webpackChunkName: "AdminDashboardView" */ './views/dashboard/AdminDashboard.view.vue'),
        // },
        {
            name: 'companiesOverview',
            path: '/admin/company/:companyId?',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.COMPANY_READ),
            component: () => import(/* webpackChunkName: "CompaniesOverviewView" */ './views/company/CompaniesOverview.view.vue'),
        },
        {
            name: 'customersOverview',
            path: '/company/:companyId/customers',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.CUSTOMER_READ_OWN),
            component: () => import(/* webpackChunkName: "CustomersOverviewView" */ './views/customer/CustomersOverview.view.vue'),
        },
        {
            name: 'locationsOverview',
            path: '/company/:companyId/locations',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.LOCATION_READ_OWN),
            component: () => import(/* webpackChunkName: "LocationsOverviewView" */ './views/location/LocationOverview.view.vue'),
        },
        {
            name: 'companyDashboard',
            path: '/company/:companyId/dashboard',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.CUSTOMER_READ_OWN),
            component: () => import(/* webpackChunkName: "DashboardView" */ './views/dashboard/CompanyDashboard.view.vue'),
        },
        {
            name: 'settings',
            path: '/company/:companyId/settings',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.COMPANY_READ_OWN),
            component: () => import(/* webpackChunkName: "SettingsView" */ './views/company/Settings.view.vue'),
        },
        {
            name: 'customerDashboard',
            path: '/company/:companyId/customer/:customerId',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.CUSTOMER_READ_OWN),
            component: () => import (/* webpackChunkName: "CustomerDashboardView" */ './views/customer/CustomerDashboard.view.vue'),
        },
        {
            name: 'locationDashboard',
            path: '/company/:companyId/customer/:customerId/location/:locationId',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.LOCATION_READ_OWN),
            component: () => import (/* webpackChunkName: "LocationDashboardView" */ './views/customer/LocationDashboard.view.vue'),
        },
        {
            name: 'usersOverview',
            path: '/company/:companyId/users',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.USER_READ_OWN),
            component: () => import(/* webpackChunkName: "UsersOverviewView" */ './views/user/UsersOverview.view.vue'),
        },
        {
            name: 'jobsOverview',
            path: '/company/:companyId/jobs',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.CLEANTIME_READ_OWN),
            component: () => import(/* webpackChunkName: "JobsOverview" */ './views/job/JobsOverview.view.vue'),
        },
        {
            name: 'timetrackingOverview',
            path: '/company/:companyId/timetracking',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.WORKSESSION_READ_OWN),
            component: () => import(/* webpackChunkName: "DashboardView" */ './views/time-tracking/TimeTrackingOverview.view.vue'),
        },
        {
          name: 'mapOverview',
          path: '/company/:companyId/map',
          beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.USER_READ_OWN),
          component: () => import(/* webpackChunkName: "DashboardView" */ './views/map/MapOverview.view.vue'),
        },
        {
            name: 'userDetails',
            path: '/company/:companyId/user/:userId',
            beforeEnter: (to, from, next) => RoutingHelper.checkRouteAccess(to, from, next, Permission.USER_READ_OWN),
            component: () => import(/* webpackChunkName: "UserDetailsView" */ './views/user/UserDetails.view.vue'),
        },
        {
            name: 'imprint',
            path: '/imprint/:lang?',
            props: {contentUrl: 'imprint'},
            alias: '/imprint',
            beforeEnter: (to, from, next) => window.location.href = 'https://peaked.me/impressum',
            component: () => import(/* webpackChunkName: "LegalInformationView" */ './views/general/LegalInformation.view.vue'),
        },
        {
            name: 'privacy-notes-app',
            path: '/privacy/app/:lang?',
            props: {contentUrl: 'privacyNotice/app'},
            alias: '/privacy/app',
            component: () => import(/* webpackChunkName: "LegalInformationView" */ './views/general/LegalInformation.view.vue'),
        },
        {
            name: 'privacy-notes-web',
            path: '/privacy/web/:lang?',
            props: {contentUrl: 'privacyNotice/web'},
            alias: '/privacy/web',
            beforeEnter: (to, from, next) => window.location.href = 'https://peaked.me/datenschutz-app',
            component: () => import(/* webpackChunkName: "LegalInformationView" */ './views/general/LegalInformation.view.vue'),
        },
        {
            path: '/privacy',
            alias: '/privacy/',
            redirect: {name: 'privacy-notes-web'},
        },
        {
            name: 'terms-and-conditions',
            path: '/termsAndConditions/:lang?',
            props: {contentUrl: 'termsAndConditions'},
            alias: '/termsAndConditions',
            beforeEnter: (to, from, next) => window.location.href = 'https://peaked.me/agb',
            component: () => import(/* webpackChunkName: "LegalInformationView" */ './views/general/LegalInformation.view.vue'),
        },
        {
            name: 'error',
            path: '/error',
            component: () => import(/* webpackChunkName: "ErrorView" */ './views/errors/error.view.vue'),
        },
        {
            path: '*',
            redirect: {name: RoutingHelper.getDefaultRoute()},
        },
    ],
});
