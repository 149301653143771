import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';

export default class ExportRepository extends EntityBaseRepository {

    public getAccountingFile(accountingDate: string): Promise<any> {
        return this.axiosClient.get(`${this.axiosClient.defaults.baseURL}export/getAccounting`,
            {
                responseType: 'blob',
                params: {
                    date: accountingDate,
                },
            });
    }
}
