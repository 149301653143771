import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import DbFile, {FileEntities, Label} from '@/models/DbFile';
import {CancelToken, CancelTokenSource} from 'axios';
import {FindAllResponse} from '@/interfaces/Responses';

export default class FileRepository extends EntityBaseRepository {

  private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
  private cancelTokenSource!: CancelTokenSource;

  constructor() {
    super();
    this.cancelTokenSource = this.cancelTokenFactory.source();
    this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
  }

  public createFile(body: FormData): Promise<DbFile> {
    return this.axiosClient.post(`dbfile`, body);
  }
  public getAllFiles(entity: FileEntities, value: string): Promise<FindAllResponse<DbFile>> {
    return this.axiosClient.get(`dbfile?${entity}=${value}&select=id,name,createdAt,label`);
  }
  public downloadFile(id: string): Promise<FindAllResponse<DbFile>> {
    return this.axiosClient.get(`dbfile/${id}/download`, {
      responseType: 'blob',
    });
  }
  public getFile(id: string, populate: string[]): Promise<DbFile> {
    return this.axiosClient.get(`dbfile/${id}?populate=${populate.join(',')}`);
  }
  public deleteFile(id: string): Promise<void> {
    return this.axiosClient.delete(`dbfile/${id}`);
  }

  public createLabel(body: Label): Promise<Label> {
    // TOTO
    return this.axiosClient.post(`label`, body);
  }

  public getLabels(companyId: string): Promise<FindAllResponse<Label>> {
    return this.axiosClient.get(`label?companyId=${companyId}`);
  }
  public deleteLabel(id: string): Promise<void> {
    return this.axiosClient.delete(`label/${id}`);
  }
}
