import Parseable from '@/misc/Parseable';

export default class Address extends Parseable {

  /**
   * Turns a plain object to a class object
   * @param object
   */
  public static parseFromObject(object: Partial<Address>): Address {
    const address = new Address();
    Object.assign(address, object);
    return address;
  }

  public id!: string;
  public street: string | null = null;
  public houseNo: string | null = null;
  public postalCode: string | null = null;
  public addition: string | null = null;
  public city: string | null = null;
  public country: string | null = null;

  public geoPosition?: { lat: number, lng: number };
  /**
   * If true, the geo position could not be obtained
   */
  public geocodePending?: boolean;

  /**
   * Returns the class object as plain object representation
   */
  public parseToObject(): Partial<Address> {
    return {...this};
  }

  /**
   * Simple Copy function to copy Address object.
   * Overrides Parseable copy function
   */
  public copy(): Address {
    return Address.parseFromObject(this.parseToObject());
  }
}
